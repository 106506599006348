<template>
  <div class="browse-view">
    <div class="ibx-app-content ibx-app-content" :class="classes">
      <div class="ibx-app-content__filters">
        <browse-filters ref="browseFilters" />
      </div>
      <div
        class="ibx-app-content__content ibx-app-content__content--browse"
        :class="contentClasses"
        ref="itemsContainer"
      >
        <ibx-items-toolbar class="browse-items-toolbar">
          <span class="browse-items-toolbar__search" slot="actions">
            <x-search
              v-show="search"
              class="browse-items-toolbar__search-control"
              :categories="searchCategories"
              delimiter=","
              placeholder="Search Items"
              prependIcon
              ref="search"
              :disabled="itemsLoading"
              @search="doSearch"
              :clearSearch="clearSearch"
            />
            <v-tooltip
              bottom
              nudge-bottom="4"
              open-delay="0"
              close-delay="0"
              transition="fade"
              content-class="custom-tooltip"
            >
              <template v-slot:activator="{ on: tooltip }">
                <span v-on="{ ...tooltip }">
                  <font-awesome-icon
                    :icon="['far', search ? 'times' : 'search']"
                    :class="searchIconClasses"
                    @click="toggleSearch"
                  />
                </span>
              </template>
              {{ search ? 'Close Search' : 'Search Items' }}
            </v-tooltip>
          </span>
          <span slot="label" v-if="hasItems">
            Showing
            <strong>{{ items.length }}</strong>
            of {{ resultsTotal }} {{ resultsLabel }}
          </span>
        </ibx-items-toolbar>
        <browse-items @item-full-screen="onItemFullScreen" @show-less="onShowLess" ref="cardItems" />
        <div class="browse-view__pagination" v-if="hasItems && hasPages">
          <x-pagination
            class="browse-view__pagination__controls"
            v-bind="getPaginationData"
            @change-page="onChangePage"
          />
        </div>
      </div>
    </div>

    <ibx-content-viewer
      v-if="hasItems"
      :items="items"
      :show="showViewer"
      :startingIndex="viewerIndex"
      attach="body"
      @close="viewerOnClose"
    >
      <component
        v-if="showViewer"
        slot="content"
        slot-scope="{ item, onItemLoaded }"
        :is="viewerCardType(item)"
        v-bind="viewerCardAttributes(item)"
        @loaded="onItemLoaded"
        @close="viewerOnClose"
      />
      <template slot="footer" slot-scope="{ item }">
        <div v-if="item && viewerFooterType(item) == 'item'">
          <x-btn v-if="itemIsAdded(item)" @click="onRemoveItem(item)" small>Remove Item</x-btn>
          <x-btn v-else @click="onAddItem(item)" small>Add Item</x-btn>
        </div>
        <div v-else class="passage_add_all">
          <v-checkbox label="Add All Items" class="select-all" hide-details v-model="allPassageItems" />
          <x-btn class="confirm" @click="confirmAddAllPassageItems(item.passage)" small>Confirm</x-btn>
        </div>
      </template>
    </ibx-content-viewer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { EventBus, Events } from '@events'
import BrowseFilters from '@components/browse/BrowseFilters'
import BrowseItems from '@components/browse/BrowseItems'
import { AppMixin, AsmtMixin } from '@mixins'
import CONST from '@constants'
import IbxItemsToolbar from '@components/ibx/_IbxItemsToolbar'
import XBtn from '@xLib/XBtn'
import * as VCheckbox from 'vuetify/es5/components/VCheckbox'
import XPagination from '@xLib/XPagination'
import { pluralizeString } from '@helpers'
import _ from 'lodash'
import XSearch from '@/components/xLib/XSearch'

export default {
  name: 'BrowseView',

  mixins: [AppMixin, AsmtMixin],

  components: {
    BrowseFilters,
    BrowseItems,
    XBtn,
    ...VCheckbox,
    IbxItemsToolbar,
    XPagination,
    XSearch,
    'ibx-content-viewer': () => import('@components/ibx/IbxContentViewer'),
    'ibx-item-card': () => import('@components/ibx/_IbxItemCard'),
    'ibx-passage-items-card': () => import('@components/ibx/_IbxPassageItemsCard'),
  },

  data: () => ({
    showViewer: false,
    viewerIndex: 0,
    allPassageItems: false,
    search: false,
    searchCategories: [
      {
        id: 'itemId',
        name: 'Item ID',
        validate: 'numeric',
        validateMessage: 'Item IDs must be numeric',
      },
      { id: 'vendorId', name: 'External ID' },
      { id: 'legacyId', name: 'Legacy ID' },
      {
        id: 'itemRevId',
        name: 'Item Rev ID',
        validate: 'numeric',
        validateMessage: 'Item Rev IDs must be numeric',
      },
      {
        id: 'passageId',
        name: 'Passage ID',
        validate: 'numeric',
        validateMessage: 'Passage IDs must be numeric',
      },
      {
        id: 'passageVendorIDs',
        name: 'External Passage ID',
      },
    ],
    clearSearch: false,
    scrollY,
  }),

  computed: {
    ...mapGetters('ui', ['browseItemsListView', 'headerIsShort']),
    ...mapGetters('browseFilters', ['selectedFilters']),
    ...mapGetters('browseItems', {
      items: 'items',
      itemsMeta: 'metadata',
      itemsLoading: 'loading',
      currentPage: 'currentPage',
      totalItems: 'totalItems',
      itemsStale: 'stale',
      passageItems: 'passageItems',
    }),
    ...mapGetters('user', ['userSelection']),
    ...mapGetters('auth', ['pieToken']),
    hasItems() {
      return this.items.length > 0
    },
    classes() {
      return this.asmtHasID ? 'ibx-app-content--create-assessment' : ''
    },
    hasPages() {
      return this.itemsMeta !== undefined && this.itemsMeta.paging !== undefined && this.itemsMeta.paging.pages > 1
    },
    getPaginationData() {
      return {
        count: this.itemsMeta.count,
        currentPage: this.itemsMeta.paging.current,
        size: this.itemsMeta.paging.size,
        pages: this.itemsMeta.paging.pages,
        threshold: 5,
        loading: this.itemsLoading,
      }
    },
    resultsTotal() {
      return this.totalItems > 500 ? '500+' : this.totalItems
    },
    resultsLabel() {
      return this.totalItems > 1 ? 'Results' : 'Result'
    },
    searchIconClasses() {
      const classes = ['browse-items-toolbar__search__icon']
      if (this.search) {
        classes.push('browse-items-toolbar__search__icon--active')
      }
      return classes
    },
    contentClasses() {
      return {
        hasAsmt: this.asmtHasID,
        'ibx-app-content__content--hide-scroll': this.showViewer,
      }
    },
  },

  watch: {
    search(v) {
      if (v) {
        this.$nextTick(() => this.$refs.search.focus())
      }
    },

    /**
     * Reload items if browse items data is stale.
     * Items data can become stale when creating/editing items
     * outise the context of browse i.e. creating editing assessment items.
     * @param {object} to route destination
     * @param {object} from route departure
     */
    $route(to, from) {
      if (to.name == 'browse' && from.name == 'assessment' && this.itemsStale) {
        this.$nextTick(() => this.loadItems())
      }
    },
  },

  methods: {
    ...mapActions('itemConfigs', ['getItemMetaConfigs', 'getItemMetaConfig']),
    ...mapActions('browseFilters', {
      init: 'init',
    }),
    ...mapActions('browseItems', {
      itemsInit: 'init',
      itemSetCurrentPage: 'setCurrentPage',
      fetchItems: 'fetchItems',
      itemsSetLoading: 'setLoading',
    }),
    ...mapActions('browseFilters', ['setAllSelectedFilters', 'setFiltersOrder', 'setActiveFilters']),
    ...mapActions('ui', ['setIsItemFullScreen']),
    ...mapMutations('browseItems', ['setItems']),
    viewerCardType(data) {
      if (data.passage) return 'ibx-passage-items-card'
      else return 'ibx-item-card'
    },
    onShowLess(index) {
      const cardItems = this.$refs.cardItems
      const elementToScrollTo = cardItems.$children[index].$el
      const containerEl = this.$refs.itemsContainer
      if (elementToScrollTo) {
        EventBus.$emit(Events.SCROLL_INTO_VIEW, {
          containerEl: containerEl,
          targetEl: elementToScrollTo,
          offset: this.asmtHasID ? 114 : 78,
        })
      }
    },
    viewerCardAttributes(data) {
      if (data.passage) {
        return {
          passage: data.passage,
          passageId: data.passage.passageId,
          passageItems: data.passage.items,
        }
      } else {
        return {
          itemId: data.itemId,
          itemRevId: data.itemRevId,
          lazy: false,
          view: 'expand',
          noAnimation: true,
          noFooter: true,
          heightAuto: true,
          close: true,
          actions: [],
          preview: true,
          context: 'provide',
          itemData: data,
        }
      }
    },
    viewerFooterType(data) {
      if (data && data.passage) return 'passage'
      else return 'item'
    },
    viewerOnClose() {
      this.showViewer = false
      this.setIsItemFullScreen(false)
      setTimeout(() => window.scrollTo(0, this.scrollY), 0)
    },
    onItemFullScreen(data) {
      if (data) {
        this.scrollY = window.scrollY
        const ids = this.items.map((o) => o.passageId || o.itemId)
        this.viewerIndex = ids.indexOf(data.passageId || data.itemId)
        this.showViewer = true
        this.setIsItemFullScreen(true)
      }
    },
    onAddItem(item) {
      if (item.itemRevId) {
        this.getItemMetaConfig({ versionedId: item.remoteIdVersioned, cacheFirst: false }).then(function ({ data }) {
          const itemRevIds = []
          if (
            data !== undefined &&
            data?.contentItemMetaData != null &&
            data?.contentItemMetaData?.k12_status !== 'RETIRED'
          ) {
            itemRevIds.push(item.itemRevId)
          }
          EventBus.$emit(Events.ADD_ITEMS, {
            itemRevIds: itemRevIds,
          })
        })
      }
    },
    onRemoveItem(item) {
      if (item.itemRevId) {
        EventBus.$emit(Events.REMOVE_ITEMS, {
          itemRevIds: [item.itemRevId],
        })
      }
    },
    getAddLabel(item) {
      if (item.itemId) {
        return this.itemIsAdded(item) ? 'Remove Item' : 'Add Item'
      }
      return 'Add Item'
    },
    itemIsAdded(item) {
      if (item.itemRevId) {
        return this.asmtItemIds.includes(item.itemRevId)
      }
      return false
    },
    confirmAddAllPassageItems(data) {
      if (this.allPassageItems) {
        let itemRevIds = data.items.map((o) => o.itemRevId)

        // collect remoteIds of corresponding itemRevIds using passageItems
        const map = new Map()
        const versionedIds = []
        this.passageItems
          .filter((item) => itemRevIds.includes(item.itemRevId))
          .forEach((item) => {
            versionedIds.push(item['remoteIdVersioned'])
            map.set(item['remoteId'], item['itemRevId'])
          })
        // Add only published items to assessment
        this.getItemMetaConfigs({ versionedIds: versionedIds, cacheFirst: false }).then(function ({ data }) {
          if (data !== undefined) {
            itemRevIds = Object.values(data)
              .filter((item) => item)
              .filter((item) => item.k12_status !== 'RETIRED')
              .map((o) => map.get(o.baseId))
          }
          EventBus.$emit(Events.ADD_ITEMS, { itemRevIds })
        })
      }
      this.allPassageItems = false // uncheck all checkbox
      this.viewerOnClose()
    },
    onChangePage(current) {
      this.itemSetCurrentPage(current).then(() => this.loadItems())
    },
    loadItems() {
      this.clearSearch = true
      this.itemsSetLoading(true)
        .then(() =>
          this.fetchItems({
            ...this.selectedFilters,
            groupByPassage: true,
            page: this.currentPage,
          })
        )
        .catch((error) => {
          this.setItems([])
          EventBus.$emit(Events.ERROR, {
            type: CONST.ERROR,
            error,
            text: CONST.BROWSE_ITEMS_LOAD_ERROR,
          })
        })
        .finally(() => {
          this.clearSearch = false
        })
    },
    /**
     * XSearch search event handler
     * Fetches items based on category and query. e.g. { itemId: [1,2,3] }.
     * Sets searching flag to true while fetching items.
     * @param arguments { category: string, query: any[] }
     */
    doSearch({ category, query }) {
      if (!this.itemsLoading) {
        const params = category == 'itemId' || category == 'itemRevId' ? this.validIntegers(query) : query
        this.itemsSetLoading(true)
        this.fetchItems({ [category]: params, groupByPassage: true })
          .catch((e) => {
            this.setItems([])
            EventBus.$emit(Events.ERROR, {
              type: CONST.ERROR,
              e,
              text: CONST.BROWSE_ITEMS_LOAD_ERROR,
            })
          })
          .finally(() => this.itemsSetLoading(false))
      }
    },
    /**
     * Return array of unique valid integers
     */
    validIntegers(vals = []) {
      return vals.filter((v) => !isNaN(v)).map((v) => Math.trunc(v))
    },
    toggleSearch() {
      if (!this.search) {
        this.search = true
      } else {
        this.closeSearch()
      }
    },
    closeSearch() {
      this.search = false
      this.$refs.search.clear(false)
      this.itemsSetLoading(false)
      this.onChangePage(this.currentPage)
    },
    onAssessmentmtItemLimit() {
      this.showViewer = false
    },

    /**
     * EventBus MUX
     * Routes event to handler
     * Ignores events if not in context of browse view
     * @param {string} event event type
     * @param {any} data event data
     */
    onEventBusEvent(event, data) {
      if (this.$route.name != 'browse') {
        return
      }

      const baseStandardsChanged = data && data.auxiliaryActions && data.auxiliaryActions.standards
      const itemsMutated = data && data.itemsMutated && data.itemsMutated.length
      switch (event) {
        case Events.EDIT_ITEMS_DONE:
          // if items and base standards changed delegate items reload to onStandardsChange.
          // onStandardsChange will only reload item if std selections changed, else reload items.
          if (itemsMutated && baseStandardsChanged) {
            const stdChanged = this.$refs.browseFilters.onStandardsChange()
            if (!stdChanged) this.loadItems()
          } else if (baseStandardsChanged) {
            this.$refs.browseFilters.onStandardsChange()
          } else if (itemsMutated) {
            this.loadItems()
          }
          break
        case Events.BROWSE_RELOAD_ITEMS:
          this.loadItems()
          break
        case Events.ASSESSMENT_ITEM_LIMIT:
          this.onAssessmentmtItemLimit()
          break
      }
    },

    async onAppInit() {
      const userSelection = this.userSelection(CONST.CONTEXT_BROWSE_FILTERS).value
      const activeFilters = _.cloneDeep(userSelection.activeFilters || [])
      this.setActiveFilters(activeFilters.length == 0 ? ['depth_of_knowledge'] : activeFilters)
      this.setFiltersOrder(userSelection.filtersOrder || [])
      this.setAllSelectedFilters(userSelection.selectedFilters || {})
      this.itemsInit({
        pieToken: this.pieToken,
        pieApiUrl: process.env.VUE_APP_PIE_URI,
      })
      return this.init()
    },
  },

  async mounted() {
    await this.appInit({ context: CONST.MODE_BROWSE })
    EventBus.$on(Events.EDIT_ITEMS_DONE, (data) => this.onEventBusEvent(Events.EDIT_ITEMS_DONE, data))
    EventBus.$on(Events.BROWSE_RELOAD_ITEMS, (data) => this.onEventBusEvent(Events.BROWSE_RELOAD_ITEMS, data))
    EventBus.$on(Events.ASSESSMENT_ITEM_LIMIT, (data) => this.onEventBusEvent(Events.ASSESSMENT_ITEM_LIMIT, data))
    //EventBus.$emit('AUTHOR_DEV')
  },
}
</script>

<style lang="scss">
@import '@/styles/main';

.browse-items-toolbar {
  margin-bottom: 20px;
}

.browse-items-toolbar__search {
  $height: auto;
  display: flex;
  align-items: center;
  margin-right: 32px;

  &-control {
    z-index: 2;
  }

  &__icon {
    font-size: 13px;
    color: $N130;
    cursor: pointer;
    width: 32px !important;
    height: 32px;
    border-radius: 50%;
    padding: 8px;
    margin-top: 4px;
    margin-left: 12px;

    &:hover,
    &--active {
      background: $N10;
    }
  }
}

.passage_add_all {
  display: flex;
  align-items: center;
  width: 100%;

  .select-all {
    margin: 0px;
  }

  .confirm {
    margin-left: auto;
  }
}
.browse-view {
  .ibx-app-content {
    margin-top: 58px;

    &__content {
      flex: 1;

      &--browse {
        // DNA-8178: content based scrolling
        // overflow-y: auto;
        // height: calc(100vh - 60px);
        padding: 0px 20px 20px 20px;
        margin-left: 370px;
        min-width: 900px;
      }

      // DNA-8178: content based scrolling
      // &--browse.hasAsmt {
      //   height: calc(100vh - 95px);
      // }

      // DNA-8178: content based scrolling
      // &--hide-scroll {
      //   overflow: hidden;
      //   height: calc(100vh - 160px);
      // }
    }

    .ibx-app-content__filters {
      top: 58px;
      transition: top 0.2s ease-in-out;
      width: 372px;

      .x-filter-group__content {
        max-height: calc(100vh - 58px - 52px - 20px); // 100% - main header - filters header - bottom padding
      }
    }
  }

  .ibx-app-content--scrolled {
    .ibx-app-content__filters {
      top: 58px;
    }
  }

  .ibx-app-content--create-assessment {
    margin-top: 95px;

    .ibx-app-content__filters {
      margin-top: 35px;
      .x-filter-group__content {
        max-height: calc(100vh - 58px - 52px - 50px); // 100% - main header - filters header - bottom padding
      }
    }
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  //transition-delay: 2.4s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
